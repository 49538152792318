import { TextField, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles({
    search: {
        marginLeft: '10px',
        '& .MuiInputBase-root': {
            color: 'white',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiInputLabel-root': {
            color: 'white',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'white',
        },
    },
})

export default function SearchField({handleInput}) {
    const styles = useStyle();
    return (
        <TextField
            onChange={handleInput}
            className={styles.search}
            id="search"
            label="Search"
            variant="outlined"
            size="small"
            style={{ marginRight: '10px' }}
        />
    )
}
