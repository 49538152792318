import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useLoading } from '../../Components/LoadingProvider';
import { Box, Card, Grid, Typography, makeStyles, Container, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import SearchField from '../../Components/SearchField';
import { networkRequest } from '../../utils/network_request';
import { apiAdmin } from '../../utils/urls';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px',
  },
  padding: {
    padding: theme.spacing(2),
  },
  margin: {
    margin: '5px',
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  listCard: {
    margin: '2px',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectedItemsContainer: {
    marginTop: '10px',
  },
  formControl: {
    width: '100%',
  },
}));

export default function AddCategory() {
  const classes = useStyles();
  const location = useLocation();
  const id = location.state || null
  const [selectedItems, setSelectedItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [testList, setTestList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { setLoading } = useLoading();
  const [category, setCategory] = useState('')
  const navigate = useNavigate()
  const isLoading = (bool) => {
    setLoading(bool)
  }

  useEffect(() => {
    if (searchQuery === '')
      return;
    const delayDebounceFn = setTimeout(() => {
      networkRequest(
        `${apiAdmin.getTests}`,
        isLoading,
        handleResponse,
        'get',
        { search: searchQuery, page: 1 }
      );
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleResponse = (response) => {
    setTestList(response['data'])
  }


  useEffect(() => {
    if (id !== null) {
      networkRequest(`${apiAdmin.categoryGetById}/${id}`, isLoading, (response) => {
        setCategory(id)
        setSelectedItems(response['data']['tests'])
      })
    }

    networkRequest(apiAdmin.categoryList, isLoading, (response) => { setCategories(response['data']) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddSelectedItem = (item) => {
    // Check if the item already exists in selectedItems array
    if (!selectedItems.some(selectedItem => selectedItem.id === item.id)) {
      // Update selected items with new item
      const updatedSelectedItems = [...selectedItems, item];
      setSelectedItems(updatedSelectedItems);
    }
  };

  const handleRemoveSelectedItem = (itemToRemove) => {
    // Remove the item from selectedItems
    const updatedSelectedItems = selectedItems.filter(item => item.id !== itemToRemove.id);
    setSelectedItems(updatedSelectedItems);
  };

  const handleSubmit = () => {
    const formData = new FormData()
    formData.append('category_id', category)
    if (id !== null) {
      //if update
      formData.append('id', id)
    }

    // Append selected test IDs
    selectedItems.forEach(item => {
      formData.append('test_id[]', item.id);
    });

    networkRequest(apiAdmin.categoryAdd, isLoading, (response) => {
      Swal.fire('success', response['message'])
      navigate(-1)
    }, 'post', formData)
  }


  return (
    <Container className={classes.root}>
      <Card>
        <Box className={clsx(classes.padding, classes.bgPrimary)}>
          <Typography variant="h6">
            Add Category
          </Typography>
        </Box>
        <Box className={classes.padding}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                >
                  {categories.map((v, i) => (<MenuItem value={v['id']}>{v['name'].toUpperCase()}</MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="primary" size='large' onClick={handleSubmit}>
                Save
              </Button>
              |
              <Button variant="contained" color="secondary" size='large' onClick={() => { navigate(-1) }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} spacing={2}>
              <Box className={clsx(classes.padding, classes.bgPrimary, classes.margin)}>
                <Typography variant="h6">
                  Selected Test
                </Typography>
              </Box>
              <ol className={classes.selectedItemsContainer}>
                {selectedItems.map((item, index) => (
                  <li key={item.id}>
                    <Card className={clsx(classes.listCard)}>
                      <span>{item.test_code}-{item.investigations}</span>
                      <Button onClick={() => handleRemoveSelectedItem(item)} color='primary'>Remove</Button>
                    </Card>
                  </li>
                ))}
              </ol>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box className={clsx(classes.padding, classes.bgPrimary, classes.margin)}>
                <Grid container spacing={0}>
                  <Grid item spacing={0} sm={6}>
                    <Typography variant="h6">
                      Select Test
                    </Typography>
                  </Grid>
                  <Grid item spacing={0} sm={6}>
                    <SearchField handleInput={(e) => { setSearchQuery(e.target.value) }} />
                  </Grid>
                </Grid>
              </Box>
              <Box className={clsx()}>
                <Grid container spacing={0}>
                  <Grid item spacing={0}>
                    <ol>
                      {testList.map((item, index) => (
                        <li key={item.id}>
                          <Card className={clsx(classes.listCard)}>
                            <span>{item.test_code}-{item.investigations}</span>
                            <Button onClick={() => handleAddSelectedItem(item)} color='primary'>Add</Button>
                          </Card>
                        </li>
                      ))}
                    </ol>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Container>
  );
}
