import React from "react";
import EmailIcon from "@material-ui/icons/Email";
import Grid from "@material-ui/core/Grid";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PhoneIcon from "@material-ui/icons/Phone";
import Wrapper from "../../Components/Wrapper";
import StatCard from "../../Components/Cards/StatCard";

const Dashboard = () => {
  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Customers"
            value={103}
            icon={<LocalOfferIcon />}
            color="#3f51b5"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Phlebotomists"
            value={230}
            icon={<PhoneIcon />}
            color="#9c27b0"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Orders"
            value={323}
            icon={<NotificationsIcon />}
            color="#f44336"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Pending Samples"
            value={870}
            icon={<EmailIcon />}
            color="#ffd740"
          />
          {/* <ChartDemo /> */}
        </Grid>
      </Grid>
      <Grid container xs={12} sm={6} md={3}></Grid>
    </Wrapper>
  );
};

export default Dashboard;
