// MenuComponent.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import routes from "./routes";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MenuComponent = () => {
  const classes = useStyles();
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleSubMenuClick = (index) => {
    setOpenSubMenu((prevIndex) => (prevIndex === index ? null : index));
  };

  const renderSubMenu = (route, index) => {
    const isOpen = openSubMenu === index;

    return (
      <React.Fragment key={route.name}>
        <ListItem button onClick={() => handleSubMenuClick(index)}>
          <ListItemIcon>
            <route.icon />
          </ListItemIcon>
          <ListItemText primary={route.name} />
          {route.children && (isOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {route.children && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {route.children.map((childRoute, childIndex) => (
                <ListItem
                  button
                  component={Link}
                  to={childRoute.path}
                  className={classes.nested}
                  key={childRoute.name}
                >
                  <ListItemIcon>
                    {/* Add icon for child route here */}
                    <route.icon />
                  </ListItemIcon>
                  <ListItemText primary={childRoute.name} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  const renderMenu = (route, index) => {
    return (
      <React.Fragment key={route.name}>
        <ListItem button component={Link} to={route.path}>
          <ListItemIcon>
            <route.icon />
          </ListItemIcon>
          <ListItemText primary={route.name} />
        </ListItem>
      </React.Fragment>
    );
  };

  return (
    <List className={classes.root}>
      {routes.map((route, index) =>
        route.children ? renderSubMenu(route, index) : renderMenu(route, index)
      )}
    </List>
  );
};

export default MenuComponent;
