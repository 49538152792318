import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Group from "@material-ui/icons/Group";
import { Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";

import { Link } from "react-router-dom";
import { useLoading } from "../../Components/LoadingProvider";
import { networkRequest } from "../../utils/network_request";
import { apiAdmin } from "../../utils/urls";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
});


const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

const CityLists = () => {
  const classes = useStyles();
  const [cities, setCities] = useState([])
  const { setLoading } = useLoading()
  const isLoading = (bool) => {
    setLoading(bool)
  }

  const getCities = () => {
    networkRequest(apiAdmin.cityGet, isLoading, (resp) => { setCities(resp['data']) })
  }

  useEffect(() => {
    getCities()
  }, [])
  return (
    <div>
      <Wrapper>
        <Card className={classes.card}>
          <CardContent>
            <Grid
              spacing={10}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h4">
                  <Group /> City List
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.textAlignRight}>
                <Link to="/cities/add-city">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "white" }}
                    startIcon={<AddCircle />}
                    size="large"
                  >
                    Add City
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Area</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cities.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.state}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.city}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.area}
                    </TableCell>
                    <TableCell align="left" className={classes.actionButtons}>
                      <Button variant="contained" color="secondary">
                        {<DeleteIcon />}
                      </Button>
                      <Button variant="contained" color="primary">
                        {<EditIcon />}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Wrapper>
    </div>
  );
};

export default CityLists;
