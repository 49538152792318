import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Card, Grid, TextField, Typography } from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { FormGroup } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useLoading } from "../../../Components/LoadingProvider";
import { networkRequest } from "../../../utils/network_request";
import { apiAdmin } from "../../../utils/urls";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "15px",
  },
  margin: {
    margin: theme.spacing(1.5),
  },
  padding: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "50ch",
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  form: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  error: {
    color: "red",
  },
}));

const AddCity = () => {
  const filter = createFilterOptions();
  const [formData, setFormData] = useState({
    city: "",
    state: "",
    area: ""
  });
  const [errors, setErrors] = useState({});
  const [states, setSTates] = useState([]);
  const [cities, setCities] = useState([]);
  const { setLoading } = useLoading()
  const navigate = useNavigate()
  const isLoading = (bool) => {
    setLoading(bool)
  }

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getStates = () => {
    networkRequest(apiAdmin.cityGet, isLoading, (resp) => { setSTates(resp['data']) }, 'get', { 'all_state': 1 })
  }

  const getCities = () => {
    networkRequest(apiAdmin.cityGet, isLoading, (resp) => { setSTates(resp['data']) }, 'get', { 'state': formData.state })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getStates() }, [])
  useEffect(() => { getCities() }, [formData.state])

  const validateForm = () => {
    const newErrors = {};

    if (formData.city === "" || formData.city.length < 5) {
      newErrors.city =
        "City name is required and must be at least 5 characters.";
    }
    if (formData.state === "" || formData.state.length > 10) {
      newErrors.state =
        "State name is required and must be at least 5 characters.";
    }

    setErrors(newErrors);
  };

  useEffect(() => {
    validateForm();
  }, [formData]);


  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData()
    data.append('state', formData.state)
    data.append('city', formData.city)
    data.append('area', formData.area)
    networkRequest(apiAdmin.cityAdd, isLoading, (resp) => {
      Swal.fire('Success', resp['message'])
      navigate(-1)
    }, 'post', data)

  };

  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        <div>
          <Grid>
            <Card>
              <Grid>
                <Typography
                  className={clsx(classes.padding, classes.bgPrimary)}
                  variant="h6"
                >
                  Add City
                </Typography>
              </Grid>
              <FormGroup className={clsx(classes.form)} onSubmit={handleSubmit}>
                <Autocomplete
                  className={clsx(classes.margin, classes.textField)}
                  style={{ marginTop: '20px' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Or Enter State" variant="outlined" />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        state: `Add "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  options={states}
                  value={formData.state}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setFormData((data) => ({ ...data, state: newValue }));
                    } else if (newValue && newValue.inputValue) {
                      setFormData((data) => ({ ...data, state: newValue.inputValue }));
                    }
                  }}
                  renderOption={(option) => option.state}
                  freeSolo
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.state;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                />
                <Autocomplete
                  className={clsx(classes.margin, classes.textField)}
                  style={{ marginTop: '20px' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Or Enter City" variant="outlined" />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        city: `Add "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  options={cities}
                  value={formData.city}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setFormData((data) => ({ ...data, city: newValue }));
                    } else if (newValue && newValue.inputValue) {
                      setFormData((data) => ({ ...data, city: newValue.inputValue }));
                    }
                  }}
                  renderOption={(option) => option.city}
                  freeSolo
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.city;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                />
                <FormControl className={clsx(classes.margin, classes.textField)}>
                  <InputLabel htmlFor="standard-adornment-area">
                    Area
                  </InputLabel>
                  <Input
                    id="standard-adornment-area"
                    type="text"
                    name="area"
                    value={formData.area}
                    onChange={handleInput}
                  />
                  {errors && errors.state && (
                    <div className={classes.error}> {errors.state}</div>
                  )}
                </FormControl>
                <Card>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={clsx(classes.margin, classes.button)}
                    startIcon={<Save />}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                  <Link to={"/cities/city-list"}>
                    <Button
                      variant="contained"
                      color="default"
                      size="large"
                      className={clsx(classes.margin, classes.button)}
                      startIcon={<Cancel />}
                    >
                      Cancel
                    </Button>
                  </Link>
                </Card>
              </FormGroup>
            </Card>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default AddCity;
