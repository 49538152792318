import { domain } from "./domain";

const apiGeneral = {
    login: `${domain}/login`,
};

const imageUrls = {
}

const apiAdmin = {
    uploadTests: `${domain}/admin/test/upload`,
    getTests: `${domain}/admin/test/get`,
    getPhlebotomists: `${domain}/admin/phlebotomist/get`,
    addPhlebotomist: `${domain}/admin/phlebotomist/add`,

    packageAdd: `${domain}/admin/package/create`,
    packageGet: `${domain}/admin/package/get`,
    packageDelete: `${domain}/admin/package/delete`,
    packageGetById: `${domain}/admin/package/get-by-id`,

    categoryAdd: `${domain}/admin/category/create`,
    categoryGet: `${domain}/admin/category/get`,
    categoryList: `${domain}/admin/category/list`,
    categoryDelete: `${domain}/admin/category/delete`,
    categoryGetById: `${domain}/admin/category/get-by-id`,

    cityAdd: `${domain}/admin/city/create`,
    cityGet: `${domain}/admin/city/get`,
    cityList: `${domain}/admin/city/list`,
    cityDelete: `${domain}/admin/city/delete`,
    cityGetById: `${domain}/admin/city/get-by-id`,

    sliderAdd: `${domain}/admin/slider/upload`,
    sliderGet: `${domain}/admin/slider/get`,
    sliderDelete: `${domain}/admin/slider/delete`
};


export { apiAdmin, apiGeneral, imageUrls };
