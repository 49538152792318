import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, TextField, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";

import Button from "@material-ui/core/Button";
import Wrapper from "../../Components/Wrapper";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px",
  },
  padding: {
    padding: theme.spacing(2),
  },
  textAlignRight: {
    textAlign: "right",
    padding: "10px",
  },
  bgPrimary: {
    backgroundColor: "#3f51b5",
    color: "white",
  },
  flexForm: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2), // Adds space between grid items vertically
    padding: "20px",
  },
  fullwidth: {
    width: "100%", // Makes the text fields full width
  },
  btnFlex: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
  },
  error: {
    color: "red",
  },
}));

const ChangePassword = () => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  const validation = useCallback(() => {
    //conditions for validation
    const newErrors = {};

    if (formData.oldPassword === "" || formData.oldPassword.length < 6) {
      newErrors.oldPassword =
        "Old password is required and must be at least 6 characters.";
    }

    if (formData.newPassword === "" || formData.newPassword.length >= 6) {
      newErrors.newPassword =
        "New password is required and must be at least 6 characters.";
    }

    if (formData.newPassword === formData.oldPassword) {
      newErrors.newPassword =
        "New password and Old Password Should not be same.";
    }

    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword =
        "New password and Confirm Password do not match.";
    }
    setErrors(newErrors);

    setErrors(newErrors);
  }, [formData]);

  const handleInput = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setFormData((predata) => ({ ...predata, [name]: value }));
  };
  useEffect(
    (e) => {
      validation();
    },
    [formData, validation]
  );
  return (
    <div>
      <Wrapper>
        <div className={classes.root}>
          {/* <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">
                <VpnKeyIcon
                  style={{ verticalAlign: "middle", padding: "10px" }}
                  fontSize="medium"
                />
                Password
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textAlignRight}></Grid>
          </Grid>
          <hr style={{ margin: "30px 0 10px" }} /> */}

          <Card>
            <Grid>
              <Typography
                className={clsx(classes.padding, classes.bgPrimary)}
                variant="h6"
              >
                Change Password
              </Typography>
            </Grid>
            <Grid className={classes.flexForm}>
              <TextField
                id="outlined-basic"
                name="oldPassword"
                label="Old Password"
                variant="standard"
                type="password"
                placeholder="Enter Old Password"
                className={clsx(classes.fullwidth)}
                onChange={handleInput}
              />
              {errors && errors.oldPassword && (
                <div className={classes.error}> {errors.oldPassword}</div>
              )}
              <TextField
                id="outlined-basic-number"
                label="New Password"
                name="NewPassword"
                type="password"
                variant="standard"
                placeholder="Enter New Password"
                className={clsx(classes.fullwidth)}
                onChange={handleInput}
              />
              {errors && errors.newPassword && (
                <div className={classes.error}> {errors.newPassword}</div>
              )}
              <TextField
                id="outlined-basic-number"
                label="Confirm New Password "
                name="confirmPassword"
                type="password"
                variant="standard"
                placeholder="Enter Confirm Password"
                className={clsx(classes.fullwidth)}
                onChange={handleInput}
              />
              {errors && errors.confirmPassword && (
                <div className={classes.error}> {errors.confirmPassword}</div>
              )}
              <div className={classes.btnFlex}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.padding}
                  startIcon={<Save />}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Card>
        </div>
      </Wrapper>
    </div>
  );
};

export default ChangePassword;
