import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Input, InputLabel, FormControl, Card, Grid, Typography, Button } from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { apiAdmin, apiGeneral } from "../../utils/urls";
import { networkRequest } from "../../utils/network_request";
import { useLoading } from "../../Components/LoadingProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "15px",
  },
  margin: {
    margin: theme.spacing(1.5),
  },
  padding: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "50ch",
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  form: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  error: {
    color: "red",
  },
}));

const Add = () => {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    address: "",
    photo: null,
  });
  const [errors, setErrors] = useState({});
  const { setLoading } = useLoading();
  const navigate = useNavigate()
  const isLoading = (bool) => {
    setLoading(bool)
  }


  const handleInput = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };


  const validateForm = () => {
    const newErrors = {};
    if (formData.name === "" || formData.name.length < 5) {
      newErrors.name = "Name is required and must be at least 5 characters.";
    }
    if (formData.contact === "" || formData.contact.length !== 10) {
      newErrors.contact = "Contact is required and must be 10 digits.";
    }
    if (formData.address === "" || formData.address.length < 5) {
      newErrors.address = "Address is required and must be at least 5 characters.";
    }
    if (formData.photo === null) {
      newErrors.photo = "Photo is required.";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // error handle
    if (Object.keys(errors).length !== 0) {
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("contact", formData.contact);
    formDataToSend.append("address", formData.address);
    formDataToSend.append("photo", formData.photo);

    networkRequest(apiAdmin.addPhlebotomist, isLoading, (response) => {
      Swal.fire('success', response['message'])
      navigate(-1)
    }, 'post', formDataToSend);
  };


  useEffect(() => {
    validateForm();
  }, [formData]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid>
        <Card>
          <Grid>
            <Typography className={clsx(classes.padding, classes.bgPrimary)} variant="h6">
              Add Phlebotomists
            </Typography>
          </Grid>
          <form className={classes.form} onSubmit={handleSubmit} encType="multipart/form-data">
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="name">Name</InputLabel>
              <Input id="name" type="text" name="name" value={formData.name} onChange={handleInput} />
              {errors.name && <div className={classes.error}>{errors.name}</div>}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="contact">Contact</InputLabel>
              <Input id="contact" type="number" name="contact" value={formData.contact} onChange={handleInput} />
              {errors.contact && <div className={classes.error}>{errors.contact}</div>}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="address">Address</InputLabel>
              <Input id="address" type="text" name="address" value={formData.address} onChange={handleInput} />
              {errors.address && <div className={classes.error}>{errors.address}</div>}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="photo">Upload Photo</InputLabel>
              <Input id="photo" type="file" name="photo" onChange={handleInput} />
              {errors.photo && <div className={classes.error}>{errors.photo}</div>}
            </FormControl>
            <Card>
              <Button variant="contained" color="primary" size="large" className={clsx(classes.margin, classes.button)} startIcon={<Save />} type="submit">
                Save
              </Button>
              <Link to="/phlebotomists">
                <Button variant="contained" color="default" size="large" className={clsx(classes.margin, classes.button)} startIcon={<Cancel />}>
                  Cancel
                </Button>
              </Link>
            </Card>
          </form>
        </Card>
      </Grid>
    </div>
  );
};

export default Add;
