import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Button, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    display: "flex",
    gap: 8,
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
    color: "white",
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  padding: {
    padding: theme.spacing(4),
  },
  btn: {
    backgroundColor: "primary"
  }
}));

const orderTableData = [
  {
    name: "SWAGAT SAYAJI DHAMBORE",
    orderStatus: "completed",
    paymentStatus: "Done",

    action: "",
  },
  {
    name: "VAISHNAVI AMBADAS KAMANDAR",
    orderStatus: "Pending",
    paymentStatus: "Pending",

    action: "",
  },
  {
    name: "GAYATRI RAMESH KHEDKAR",
    orderStatus: "completed",
    paymentStatus: "Done",

    action: "",
  },
  {
    name: "SAIDEEP PRAFULLA RAUT",
    orderStatus: "completed",
    paymentStatus: "Pending",

    action: "",
  },
  {
    name: "RUDRAPRATAP NILKANTH LABADE",
    orderStatus: "Pending",
    paymentStatus: "Pending",

    action: "",
  },
  {
    name: "OMKAR NITIN SHINDE",
    orderStatus: "completed",
    paymentStatus: "Done",

    action: "",
  },
];

export const phelbotomistFilters = [
  {
    id: 1,
    value: "Aditya Salve",
    label: "Aditya Salve",
  },
  {
    id: 2,
    value: "Harsh Tupe",
    label: "Harsh Tupe",
  },
  {
    id: 3,
    value: "Yogesh More",
    label: "Yogesh More",
  },
];
export const orderFilters = [
  {
    id: 1,
    value: "Pending Orders",
    label: "Pending Orders",
  },
  {
    id: 2,
    value: "Complete Orders",
    label: "Complete Orders",
  },
  {
    id: 3,
    value: "Cancel Orders",
    label: "Cancel Orders",
  },
  {
    id: 4,
    value: "Phlebotomists",
    label: "Phlebotomists",
  },
];

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

// Modal Category

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h2>{children}</h2>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
// Modal Category
const OrderLists = () => {
  const classes = useStyles();
  const [currency, setCurrency] = React.useState("All");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onClose = () => {
    setIsModalOpen(false);
  };
  const onSave = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Wrapper>
        <Card className={classes.card}>
          <Grid sm={12}>
            <Typography
              className={clsx(classes.padding, classes.bgPrimary)}
              variant="h4"
            >
              Order List
            </Typography>
          </Grid>
        
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            sm={12}
            className={classes.bgPrimary}
          >
            <Grid items style={{ marginRight: "10px", backgroundColor: "white", padding: "6px", borderRadius: "5px" }}>
            <TextField
              id="outlined-select-currency-native"
              select
              label="Phelbotomist"
              value={currency}
              onChange={handleChange}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              className={classes.dropDownField}
              size="small"
            >
              {phelbotomistFilters.map((option, id) => (
                <option key={id} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
              <TextField
                // style={{backgroundColor:"white"}}
                id="outlined-select-currency-native"
                select
                label="Filter"
                value={currency}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                className={classes.dropDownField}
                size="small"
              >
                {orderFilters.map((option, id) => (
                  <option key={id} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <Button variant="contained" style={{ backgroundColor: "white" }} size="medium">
                Filter
              </Button>
            </Grid>
          </Grid>
        </Card>

        <hr style={{ margin: "30px 0 10px" }} />

        <Grid container spacing={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Order Status</TableCell>
                  <TableCell align="right">Payment Status</TableCell>
                  <TableCell align="center">Assign Phelbotomists</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderTableData.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      <Link
                        to={"/orders/order-details"}
                        style={{ textDecoration: "none" }}
                      >
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{row.orderStatus}</TableCell>
                    <TableCell align="right">{row.paymentStatus}</TableCell>

                    <TableCell align="right">
                      {" "}
                      <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Phelbotomist"
                        value={currency}
                        onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        className={classes.dropDownField}
                        size="small"
                      >
                        {phelbotomistFilters.map((option, id) => (
                          <option key={id} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <span style={{ marginRight: "10px" }}>
                          Upload Reports
                        </span>{" "}
                        {<BackupOutlinedIcon />}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Wrapper>
      {/* Modal */}
      <Wrapper>
        <Dialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={isModalOpen}
        >
          <DialogTitle id="customized-dialog-title" onClose={onclose}>
            Upload Reports
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={3}
              justify="space-between"
              alignItems="center"
              className={classes.flexFormDropdown}
            >
              <Grid item style={{ width: "100%" }}>
                <TextField id="outlined-basic" variant="outlined" type="file" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              onClick={onClose}
              color="primary"
            >
              Close
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={onSave}
              color="default"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
      {/* Modal */}
    </div>
  );
};

export default OrderLists;
