import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import dashboardImg from "../../assets/images/Dashboard-bro.svg";
import Wrapper from "../../Components/Wrapper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { apiGeneral } from "../../utils/urls";
import axios from "axios";
import Swal from "sweetalert2";
import { FormHelperText } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://sublimetechnologies.in/">
        Sublime Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundRepeat: "no-repeat",

    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide image on small devices
    },
  },
  imgDivShadow: {
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorhelperText: {
    color: 'red'
  }
}));

export default function Login() {
  const classes = useStyles();

  const navigate = useNavigate();
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState({
    contact: '',
    password: '',
  })
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object

    // Validate contact
    if (formData.contact.trim() === "" || formData.contact.length !== 10) {
      errorsCopy.contact = "Contact is required and must be 10 digits.";
    }

    // Validate password
    if (formData.password.trim() === "" || formData.password.length < 5) {
      errorsCopy.password = "Password is Required";
    }

    setErrors(errorsCopy);
  };


  useEffect(
    (e) => {
      validateForm();
    },
    [formData]
  );
  useEffect(() => { loginCheck() }, [])

  const loginCheck = () => {
    const accessToken = localStorage.getItem("access_token") ?? null;

    if (accessToken !== null) {
      navigate('/dashboard')
    }

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(errors)
    if (Object.keys(errors).length !== 0) {
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("contact", formData.contact);
    formDataToSend.append('password', formData.password);


    try {
      const response = await axios.post(apiGeneral.login, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response:", response.data);

      Swal.fire({ title: "SUCCESS", text: response.data["message"] });
      if (response.data["success"]) {
        localStorage.setItem("access_token", response.data["access_token"]);
        localStorage.setItem("refresh_token", response.data["refresh_token"]);
        localStorage.setItem("id", response.data['data']["id"]);
        localStorage.setItem("name", response.data['data']["name"]);
        localStorage.setItem("role", response.data['data']["role"]);
      }
      if (response.data["success"])
        return navigate("/dashboard");
    } catch (error) {
      console.error("Error during form submission:", error);
      Swal.fire('Error', error.response.data['message']);
    }
  }


  return (
    <Wrapper>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7}>
          {/* <img src={dashboardImg} alt="" className={classes.image} /> */}
          <LazyLoadImage
            alt={"tex"}
            effect="blur"
            className={classes.image}
            src={dashboardImg}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="contact"
                label="Contact Address"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                autoComplete="contact"
                autoFocus
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
              {errors.contact && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.contact}
                </FormHelperText>
              )}

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}

                id="password"
                autoComplete="current-password"
              />
              {errors.password && (
                <FormHelperText className={classes.errorhelperText} color="secondary">
                  {errors.password}
                </FormHelperText>
              )}
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}

              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs></Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>

          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
