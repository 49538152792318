import axios from "axios";
import Swal from "sweetalert2";

const networkRequest = async (
  url,
  isLoading,
  callback,
  requestType = "get",
  formData = null
) => {
  try {
    isLoading(true);
    let isTokenOk = true;
    let response;

    while (isTokenOk) {
      const headers = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token"),
          "Refresh-token": localStorage.getItem("refresh_token"),
        },
      };

      if (requestType === "get") {
        if (formData)
          headers['params'] = formData;
        response = await axios.get(url, headers);
      } else {
        response = await axios.post(url, formData, headers);
      }

      if (response.data["success"]) {
        callback(response.data);
        isTokenOk = false;
      } else {
        if (response.data["message"] === "Token Expired") {
          localStorage.setItem("token", response.data["access-token"]);
        } else {
          isTokenOk = false;
          Swal.fire({
            title: "Oops",
            text: JSON.stringify(response.data["message"]),
            icon: "warning",
          });
        }
      }
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status } = error.response;

      if (status === 400 || status === 401) {
        // Bad Request
        // localStorage.clear();
        // return (window.location.href = "/login");
      }

      // Display a SweetAlert message for any error, not just 400
      Swal.fire({
        title: "Oops",
        text: error.response.data['message'],
        icon: "error",
        type: "error",
      });

      // Handle other HTTP status codes if needed
    }

    console.error("Error fetching data:", error);
  } finally {
    isLoading(false);
  }
};

export { networkRequest };
