import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Group from "@material-ui/icons/Group";
import { Box, Checkbox, Tooltip, Typography } from "@material-ui/core";
import { AddCircle, CloudUpload } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import { Link } from "react-router-dom";
import { apiAdmin, apiGeneral } from "../../utils/urls";
import { networkRequest } from "../../utils/network_request";
import { Pagination } from "@material-ui/lab";
import SearchField from "../../Components/SearchField";
import { useLoading } from "../../Components/LoadingProvider";

const useStyles = makeStyles({
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h2>{children}</h2>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

const TestLists = () => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [testList, setTestList] = useState([]);
  const [tablePages, setTablePages] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const { setLoading } = useLoading()
  const isLoading = (bool) => {
    setLoading(bool);
  };

  const [searchQuery, setSearchQuery] = useState('')
  const handlePagination = (e, v) => {
    setActivePage(v);
  };

  const fetchTestListData = () => {
    networkRequest(
      `${apiAdmin.getTests}`,
      isLoading,
      handleResponse,
      'get',
      { search: searchQuery, page: activePage }
    );
  };


  const handleResponse = (response) => {
    setTestList(response["data"]);
    setTablePages(response["total_pages"]);
  };

  useEffect(() => {
    fetchTestListData();
  }, [activePage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setActivePage(1)
      networkRequest(
        `${apiAdmin.getTests}`,
        isLoading,
        handleResponse,
        'get',
        { search: searchQuery, page: activePage }
      );
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };




  const onSave = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      networkRequest(
        `${apiAdmin.uploadTests}`, handleResponse, 'post', formData,
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          // Handle success response
          setIsModalOpen(false);
          fetchTestListData(); // Refresh test list after upload
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          // Handle error response
        });
    }
  };

  return (
    <div>
      <Wrapper>
        <Card className={classes.card}>
          <CardContent>
            <Grid
              container
              spacing={10}
              direction="row"
              className={classes.justifyContentSpaceBetween}
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  <Group /> Tests List
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.textAlignRight}>
                <SearchField handleInput={(e) => {
                  setSearchQuery(e.target.value)
                }} />
                <Button
                  variant="contained"
                  style={{ backgroundColor: "white" }}
                  startIcon={<AddCircle />}
                  size="medium"
                  onClick={() => setIsModalOpen(true)}
                >
                  Upload Tests
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Check</TableCell>
                  <TableCell>Sr. No</TableCell>
                  <TableCell>Test Code</TableCell>
                  <TableCell>Investigations </TableCell>
                  <TableCell>Methodology </TableCell>
                  <TableCell>Sample </TableCell>
                  <TableCell>Report </TableCell>
                  <TableCell>Schedule Reported </TableCell>
                  <TableCell align="left">MRP</TableCell>
                  <TableCell align="left">Selling Price</TableCell>
                  <TableCell>Action</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {testList.map((row) => (
                  <TableRow key={row.SrNo}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.SrNo}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.test_code}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.investigations}
                    </TableCell>
                    <TableCell align="left">{row.methodology}</TableCell>
                    <TableCell align="left">{row.sample}</TableCell>
                    <TableCell align="left">{row.emergency_report}</TableCell>
                    <TableCell align="left">{row.schedule_report}</TableCell>
                    <TableCell align="left">{row.cost_price}</TableCell>
                    <TableCell align="left">{row.sale_price}</TableCell>

                    <TableCell align="left" >
                      <Tooltip title="Add Recommend test" >
                        <Link to={{ pathname: '/recommended_tests', state:  row.id }}>
                          <Button variant="contained" color="primary">
                            <ThumbUpAltOutlinedIcon />
                          </Button>
                        </Link>

                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px",
            }}
          >
            <Pagination
              count={tablePages}
              color="primary"
              style={{ marginRight: "20px" }}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
            />
          </Box>
        </Grid>
      </Wrapper>

      <Wrapper>
        <Dialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={isModalOpen}
        >
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            Upload File
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item style={{ width: '100%' }}>
                <input
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUpload />}
                  >
                    Upload Test
                  </Button>
                </label>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button autoFocus onClick={onSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    </div>
  );
};

export default TestLists;
