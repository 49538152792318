import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Screens/login/Login";
import Dashboard from "./Screens/dashboard/Dashboard";
import MainLayout from "./Components/Layouts/MainLayout";
import Phlebotomists from "./Screens/phlebotomists/Phlebotomists";
import Packages from "./Screens/packages/Packages";
import ChangePassword from "./Screens/change-password/ChangePassword";
import CityLists from "./Screens/city/CityLists";
import CustomerLists from "./Screens/customers/CustomerLists";
import OrderLists from "./Screens/orders/OrderLists";
import TestLists from "./Screens/test-lists/TestLists";
import Add from "./Screens/phlebotomists/Add";
import AddCity from "./Screens/city/forms/AddCity";
import CustomerProfilePage from "./Screens/customers/CustomerProfilePage";
import OrderDetails from "./Screens/orders/OrderDetails";
import AppSliderImages from "./Screens/app-slider-images/AppSliderImages";
import UploadSlider from "./Screens/app-slider-images/UploadSlider";
import RecommendedTests from "./Screens/test-lists/RecommendedTests";
import { LoadingProvider } from "./Components/LoadingProvider";
import AddPackages from "./Screens/packages/AddPackages";
import Categories from "./Screens/categories/Categories";
import AddCategory from "./Screens/categories/AddCategories";

function App() {
  return (
    <BrowserRouter>
      <LoadingProvider >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<MainLayout page={Dashboard} />} />
          <Route
            path="/customers/list"
            element={<MainLayout page={CustomerLists} />}
          />
          <Route
            path="/customers/profile"
            element={<MainLayout page={CustomerProfilePage} />}
          />
          <Route
            path="/phlebotomists"
            element={<MainLayout page={Phlebotomists} />}
          />
          <Route path="/phlebotomists/add" element={<MainLayout page={Add} />} />
          <Route path="/test/list" element={<MainLayout page={TestLists} />} />
          <Route path="/recommended_tests" element={<MainLayout page={RecommendedTests} />} />

          <Route path="/packages" element={<MainLayout page={Packages} />} />
          <Route path="/packages/add" element={<MainLayout page={AddPackages} />} />

          <Route path="/categories" element={<MainLayout page={Categories} />} />
          <Route path="/category/add" element={<MainLayout page={AddCategory} />} />

          <Route
            path="/app-slider-images"
            element={<MainLayout page={AppSliderImages} />}
          />
          <Route
            path="/app-slider-images/uploadslider"
            element={<MainLayout page={UploadSlider} />}
          />

          <Route path="/orders/list" element={<MainLayout page={OrderLists} />} />
          <Route
            path="/orders/order-details"
            element={<MainLayout page={OrderDetails} />}
          />
          <Route
            path="/cities/city-list"
            element={<MainLayout page={CityLists} />}
          />
          <Route
            path="/cities/add-city"
            element={<MainLayout page={AddCity} />}
          />
          <Route
            path="/change-password"
            element={<MainLayout page={ChangePassword} />}
          />
        </Routes>
      </LoadingProvider>
    </BrowserRouter>
  );
}

export default App;
