import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";

import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import ListIcon from "@material-ui/icons/List";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";

import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import AssistantOutlinedIcon from "@material-ui/icons/AssistantOutlined";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import { AddPhotoAlternate } from "@material-ui/icons";

const routes = [
  {
    name: "Dashboard",
    icon: DashboardOutlinedIcon,
    path: "/dashboard",
    badge: 0,
  },
  {
    name: "Customers",
    icon: SupervisedUserCircleOutlinedIcon,
    path: "/customers/list  ",
    badge: 0,
    // children: [
    //   {
    //     name: "List",
    //     icon: ListIcon,
    //     path: "/customers/list",
    //     badge: 0,
    //   },
    // ],
  },
  {
    name: "Phlebotomists",
    icon: LocalHospitalOutlinedIcon,
    path: "/phlebotomists",
    badge: 0,
  },
  {
    name: "Test Lists",
    icon: ListAltOutlinedIcon,
    path: "/test/list",
    badge: 0,
    // children: [
    //   {
    //     name: "List",
    //     icon: ListIcon,
    //     path: "/test/list",
    //     badge: 0,
    //   },
    // ],
  },
  {
    name: "Packages",
    icon: AssistantOutlinedIcon,
    path: "/packages",
    badge: 0,
  },
  {
    name: "Category",
    icon: AssistantOutlinedIcon,
    path: "/categories",
    badge: 0,
  },
  {
    name: "App Slider Images",
    icon: AddPhotoAlternate,
    path: "/app-slider-images",
    badge: 0,
  },
  {
    name: "Orders",
    icon: MonetizationOnOutlinedIcon,
    path: "/orders/list",
    badge: 0,
    // children: [
    //   {
    //     name: "List of Orders",
    //     icon: ListIcon,
    //     path: "/orders/list",
    //     badge: 0,
    //   },
    // ],
  },
  {
    name: "Cities",
    icon: ExploreOutlinedIcon,
    path: "/cities/city-list",
    badge: 0,
  },

  {
    name: "Change Password",
    icon: VpnKeyIcon,
    path: "/change-password",
    badge: 0,
  },
];

export default routes;
