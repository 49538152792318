import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { AddCircle, AddPhotoAlternate } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Wrapper from "../../Components/Wrapper";
import DeleteIcon from "@material-ui/icons/Delete";
import { useLoading } from "../../Components/LoadingProvider";
import { networkRequest } from "../../utils/network_request";
import { apiAdmin } from "../../utils/urls";
import { domain } from "../../utils/domain";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    margin: "15px",
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  margin: {
    margin: theme.spacing(1.5),
  },
  padding: {
    padding: theme.spacing(2),
  },

  textAlignRight: {
    textAlign: "right",
  },
}));

const Add = () => {
  const classes = useStyles();
  const [sliders, SetSlider] = useState([]);
  const { setLoading } = useLoading()
  const isLoading = (bool) => {
    setLoading(bool)
  }
  const getSliders = () => {
    networkRequest(apiAdmin.sliderGet, isLoading, (resp) => { SetSlider(resp['data']) });
  }

  useEffect(() => {
    getSliders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        networkRequest(`${apiAdmin.sliderDelete}/${id}`, setLoading, (response) => {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          getSliders(); // Assuming getSliders is defined to fetch the updated list of sliders
        });
      }
    });
  };

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">
                <AddPhotoAlternate /> Slider Images
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textAlignRight}>
              <Link to="/app-slider-images/uploadslider">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "white" }}
                  startIcon={<AddCircle />}
                  size="large"
                >
                  Add Slider
                </Button>
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr.No</TableCell>
                <TableCell>Slider</TableCell>
                <TableCell>Images</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sliders.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <img src={`${domain}/${row.image}`}
                      alt=""
                      height={"100px"}
                      width={"100px"} />
                  </TableCell>
                  <TableCell align="left" className={classes.actionButtons}>
                    <Button variant="contained" color="secondary" onClick={() => {
                      handleDelete(row.id)
                    }}>
                      {<DeleteIcon />}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Wrapper>
  );
};

export default Add;
