import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

import Card from "@material-ui/core/Card";

import Group from "@material-ui/icons/Group";
import { InputBase, TextField, Toolbar, Typography } from "@material-ui/core";
import clsx from "clsx";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    display: "flex",
    gap: 8,
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
    backgroundColor:"white",
    color:'black'
  },

  textAlignRight: {
    textAlign: "right",
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  padding: {
    padding: theme.spacing(4),
  },

}));

const customerRows = [
  {
    Sr: 1,

    name: "kiran salve",
    contact: "7499667341",
    email: "satyashilmagre@gmail.com",
    address: "himayat bagh",
    gender: "male",
    dob: "1/6/2000",
    location: "aurangabad",
    medical_History: "Diabetes",
    action: "",
  },
  {
    Sr: 2,
    name: "Sagar Magare",
    contact: "7499664455",
    email: "sagarmagre@gmail.com",
    address: "sanjay nagar",
    gender: "male",
    dob: "20/4/1999",
    location: "aurangabad",
    medical_History: "Thyroid",
    action: "",
  },
  {
    Sr: 3,
    name: "Mirza ",
    contact: "9088990220",
    email: "mirza@gmail.com",
    address: "aaref colony",
    gender: "male",
    dob: "1/6/2000",
    location: "aurangabad",
    medical_History: "High Blood pressure",
    action: "",
  },
  {
    Sr: 4,
    name: "kiran mahske",
    contact: "9870424145",
    email: "kiranmahske@gmail.com",
    address: "n-12 ",
    gender: "male",
    dob: "29/6/1995",
    location: "aurangabad",
    medical_History: "Diabetes",
    action: "",
  },
  {
    Sr: 5,
    name: "hussain",
    contact: "8888909045",
    email: "huasain@gmail.com",
    address: "vaijapur",
    gender: "male",
    dob: "1/8/2000",
    location: "aurangabad",
    medical_History: "Headache",
    action: "",
  },
  {
    Sr: 6,
    name: "pooja",
    contact: "7488888887",
    email: "pooja@gmail.com",
    address: "moda naka",
    gender: "female",
    dob: "1/6/2000",
    location: "aurangabad",
    medical_History: "Diabetes",
    action: "",
  },
];

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export default function CustomerList() {
  const classes = useStyles();

  return (
    <Wrapper>
      <Card className={classes.card}>
        <Grid sm={12}>
          <Typography
            className={clsx(classes.padding, classes.bgPrimary)}
            variant="h4"
          >
             <Group /> Customer List
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className={classes.bgPrimary}
        >
          <Grid>
            <Grid item xs></Grid>
            <Grid item xs></Grid>
            <Grid item xs></Grid>
            <Grid item xs></Grid>
            <Grid item xs>
              <Toolbar>
                <div className={classes.search}>
                  <Search />
                  <InputBase
                
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={1}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Contact No</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Address</TableCell>
                <TableCell align="left">Gender</TableCell>
                <TableCell align="left">DOB/AGE</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="left">Medical History</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerRows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.Sr}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.contact}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell align="left">{row.address}</TableCell>
                  <TableCell align="left">{row.gender}</TableCell>
                  <TableCell align="left">{row.dob}</TableCell>
                  <TableCell align="left">{row.location}</TableCell>
                  <TableCell align="left">{row.medical_History}</TableCell>
                  <TableCell align="left" className={classes.actionButtons}>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: 4 }}
                    >
                      {<DeleteIcon />}
                    </Button>
                    <Link to={"/customers/profile"}>
                      <Button variant="contained" color="primary">
                        {<VisibilityIcon />}
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Wrapper>
  );
}
