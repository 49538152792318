import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Group from "@material-ui/icons/Group";
import { Typography } from "@material-ui/core";

import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { AddCircle } from "@material-ui/icons";
import { useLoading } from "../../Components/LoadingProvider";
import { networkRequest } from "../../utils/network_request";
import { apiAdmin } from "../../utils/urls";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Swal from "sweetalert2";


const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
});

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

const Categories = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([])
  const { setLoading } = useLoading()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const isLoading = (bool) => {
    setLoading(bool)
  }
  const getCategories = () => {
    networkRequest(apiAdmin.categoryGet, isLoading, (response) => {
      setCategories(response['data'])
    }, 'get', { page: currentPage })
  }

  useEffect(() => {
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {" "}
      <Wrapper>
        <Card className={classes.card}>
          <CardContent>
            <Grid
              spacing={10}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h4">
                  <Group /> Categories
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.textAlignRight}>
                <Link to="/category/add">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "white" }}
                    startIcon={<AddCircle />}
                    size="large"
                  >
                    Add Categories
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No</TableCell>
                  <TableCell>Package Name</TableCell>
                  <TableCell>Tests </TableCell>
                  <TableCell align="left">Action </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name.toUpperCase()}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Accordion style={{ width: "300px" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            {row.parameters.split(',')[0]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ol>
                            {/* Map through testNames array */}
                            {row.parameters &&
                              row.parameters.split(',').map((testName, index) => (
                                <li key={index}>
                                  <Typography>{testName}</Typography>
                                </li>
                              ))}
                          </ol>
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.actionButtons}
                      style={{ textDecoration: "line-through", color: "gray" }}>
                      <Button variant="contained" color="primary" onClick={() => { navigate('/category/add', { state: row.id }) }}>
                        {<EditIcon />}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Wrapper>
    </div>
  );
};

export default Categories;
