import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import Wrapper from "../../Components/Wrapper";
import { useLoading } from "../../Components/LoadingProvider";
import { networkRequest } from "../../utils/network_request";
import { apiAdmin } from "../../utils/urls";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px",
  },
  margin: {
    margin: theme.spacing(1.5),
  },
  padding: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "50ch",
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  form: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  error: {
    color: "red",
  },
}));

const UploadSlider = () => {
  const [formData, setFormData] = useState({
    type: "",
    photo: null,
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      photo: file,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.photo) {
      newErrors.photo = "Image is required.";
    }
    if (!formData.type) {
      newErrors.type = "Please select type.";
    }
    setErrors(newErrors);
    return newErrors;
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    const form = new FormData();
    form.append("type", formData.type);
    if (formData.photo) {
      form.append("file", formData.photo);
    }

    networkRequest(apiAdmin.sliderAdd, setLoading, (response) => {
      Swal.fire("Success", response.message, "success");
      navigate(-1);
    }, 'post', form);
  };

  const typeOptions = [
    { id: 1, value: "main-slider", label: "Main Slider" },
    { id: 2, value: "banner-1", label: "Banner 1" },
    { id: 3, value: "banner-2", label: "Banner 2" },
  ];

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid>
        <Card>
          <Grid>
            <Typography
              className={clsx(classes.padding, classes.bgPrimary)}
              variant="h5"
            >
              Add Slider Images
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit} className={classes.form}>
            <FormGroup className={classes.margin}>
              <FormControl className={classes.textField}>
                <TextField
                  select
                  label="Category"
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                  SelectProps={{ native: true }}
                  variant="outlined"
                  size="small"
                >
                  <option key='0' value=''>
                    Select Type
                  </option>
                  {typeOptions.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                {errors.type && <div className={classes.error}>{errors.type}</div>}
              </FormControl>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor="photo">Upload Photo</InputLabel>
                <Input
                  id="photo"
                  type="file"
                  name="photo"
                  onChange={handleFileChange}
                />
                {errors.photo && <div className={classes.error}>{errors.photo}</div>}
              </FormControl>
              <Card>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.margin}
                  startIcon={<Save />}
                  type="submit"
                >
                  Save
                </Button>
                <Link to="/app-slider-images">
                  <Button
                    variant="contained"
                    color="default"
                    size="large"
                    className={classes.margin}
                    startIcon={<Cancel />}
                  >
                    Cancel
                  </Button>
                </Link>
              </Card>
            </FormGroup>
          </form>
        </Card>
      </Grid>
    </div>
  );
};

export default UploadSlider;
