import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Box, Button, Typography } from "@material-ui/core";
import { Group } from "@material-ui/icons";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
  formControl: {
    minWidth: 120,
  },
  select: {
    width: "200px",
  },
  displayFlexCopyIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  copyIconwidth: {
    marginLeft: "10px",
    marginTop: "9px",
    cursor: "pointer",
  },
  textTypographyDetails: {
    display: "flex",
    alignItems: "center",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

const orderTableData = [
  {
    SrNo: 1,
    img: "https://plus.unsplash.com/premium_photo-1714303744946-4c8ce259d5f3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxfHx8ZW58MHx8fHx8",
    name: "Liver Functions",
    productCode: "a11yProps",
    quantity: "2",
    price: "200",
    action: "",
  },
  {
    SrNo: 2,
    img: "https://plus.unsplash.com/premium_photo-1714303744946-4c8ce259d5f3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxfHx8ZW58MHx8fHx8",
    name: "Diabetes",
    productCode: "a12yProps",
    quantity: "4",
    price: "200",
    action: "",
  },
  {
    SrNo: 3,
    img: "https://plus.unsplash.com/premium_photo-1714303744946-4c8ce259d5f3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxfHx8ZW58MHx8fHx8",
    name: "Thyroid",
    productCode: "a13yProps",
    quantity: "2",
    price: "200",
    action: "",
  },
];

const OrderDetails = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <>
        <Wrapper>
          <Card className={classes.card}>
            <CardContent>
              <Grid
                spacing={10}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="h4">
                    <Group /> Order Details
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid item xs={12} sm={12}>
            <Box>
              <div className={classes.root}>
                <AppBar
                  position="static"
                  style={{ backgroundColor: "#fff", color: "black" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Customer Details" {...a11yProps(0)} />
                    {/* <Tab label="Family" {...a11yProps(1)} />
                    <Tab label="Orders" {...a11yProps(2)} /> */}
                  </Tabs>
                </AppBar>
                {/* General Details */}
                <TabPanel value={value} index={0}>
                  <Card>
                    <CardContent>
                      <div style={{ padding: "20px" }}>
                        <Typography variant="h6">Personal Details</Typography>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "80px",
                            padding: "20px",
                          }}
                        >
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Last Name
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>John</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              First Name
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>Marston</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Medical History
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>Diabetes</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Birthdate
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>03/03/1996</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Gender
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>Male</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </Box>
                      </div>
                      <div style={{ padding: "20px" }}>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "80px",
                            padding: "20px",
                          }}
                        >
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Address
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>Himayat Baugh</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Phone Number
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>9890348104</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Email
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>john@gmail.com</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </Box>
                      </div>
                      {/* <div style={{ padding: "20px" }}>
                        <Typography variant="h6">Prescriptions</Typography>
                        <Box style={{ display: "flex", gap: "30px" }}>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-native-simple">
                              Delivery Type
                            </InputLabel>
                            <Select
                              native
                              onChange={handleChange}
                              inputProps={{
                                name: "age",
                                id: "age-native-simple",
                              }}
                              className={classes.select}
                            >
                              <option aria-label="None" value="" />
                              <option value={10}>Ten</option>
                              <option value={20}>Twenty</option>
                              <option value={30}>Thirty</option>
                            </Select>
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-native-simple">
                              Delivery Route
                            </InputLabel>
                            <Select
                              native
                              onChange={handleChange}
                              inputProps={{
                                name: "age",
                                id: "age-native-simple",
                              }}
                              className={classes.select}
                            >
                              <option aria-label="None" value="" />
                              <option value={10}>Ten</option>
                              <option value={20}>Twenty</option>
                              <option value={30}>Thirty</option>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box style={{ display: "flex", gap: "30px" }}>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-native-simple">
                              Price Group
                            </InputLabel>
                            <Select
                              native
                              onChange={handleChange}
                              inputProps={{
                                name: "age",
                                id: "age-native-simple",
                              }}
                              className={classes.select}
                            >
                              <option aria-label="None" value="" />
                              <option value={10}>Ten</option>
                              <option value={20}>Twenty</option>
                              <option value={30}>Thirty</option>
                            </Select>
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-native-simple">
                              Drug Line
                            </InputLabel>
                            <Select
                              native
                              onChange={handleChange}
                              inputProps={{
                                name: "age",
                                id: "age-native-simple",
                              }}
                              className={classes.select}
                            >
                              <option aria-label="None" value="" />
                              <option value={10}>Ten</option>
                              <option value={20}>Twenty</option>
                              <option value={30}>Thirty</option>
                            </Select>
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-native-simple">
                              Drug Line 2
                            </InputLabel>
                            <Select
                              native
                              onChange={handleChange}
                              inputProps={{
                                name: "age",
                                id: "age-native-simple",
                              }}
                              className={classes.select}
                            >
                              <option aria-label="None" value="" />
                              <option value={10}>Ten</option>
                              <option value={20}>Twenty</option>
                              <option value={30}>Thirty</option>
                            </Select>
                          </FormControl>
                        </Box>
                      </div> */}
                    </CardContent>
                  </Card>
                  <Card style={{ marginTop: "10px" }}>
                    {/* <CardContent>
                      <div style={{ padding: "20px" }}>
                        <Typography variant="h6">Order Details</Typography>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "80px",
                            padding: "20px",
                          }}
                        >
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Last Name
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>John</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              First Name
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>Marston</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Medical History
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>Diabetes</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Birthdate
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>03/03/1996</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Gender
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>Male</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </Box>
                      </div>
                      <div style={{ padding: "20px" }}>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "80px",
                            padding: "20px",
                          }}
                        >
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Address
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>Himayat Baugh</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Phone Number
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>9890348104</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "gray" }}
                            >
                              Email
                            </Typography>
                            <div className={classes.displayFlexCopyIcon}>
                              <Typography
                                variant="subtitle1"
                                className={classes.textTypographyDetails}
                              >
                                <span>john@gmail.com</span>
                                <span className={classes.copyIconwidth}>
                                  <FileCopyOutlinedIcon />
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </Box>
                      </div>
                      
                    </CardContent> */}
                    <AppBar
                      position="static"
                      style={{ backgroundColor: "#fff", color: "black" }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                      >
                        <Tab label="Order Details" {...a11yProps(0)} />
                        {/* <Tab label="Family" {...a11yProps(1)} />
                    <Tab label="Orders" {...a11yProps(2)} /> */}
                      </Tabs>
                    </AppBar>
                    <CardContent>
                      <Grid container spacing={1}>
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Sr No</TableCell>
                                <TableCell align="right">
                                  Product Image
                                </TableCell>
                                <TableCell align="right">Name</TableCell>

                                <TableCell align="right">
                                  Product Code
                                </TableCell>

                                <TableCell align="right">Quantity</TableCell>
                                <TableCell align="right">Price</TableCell>

                                <TableCell align="right">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {orderTableData.map((row) => (
                                <TableRow key={row.name}>
                                  <TableCell component="th" scope="row">
                                    {row.SrNo}
                                  </TableCell>
                                  <TableCell align="right">
                                    <img
                                      src={row.img}
                                      alt=""
                                      height={"50px"}
                                      width={"50px"}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.productCode}
                                  </TableCell>

                                  <TableCell align="right">
                                    {row.quantity}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.price}
                                  </TableCell>

                                  <TableCell align="right">
                                    <Button variant="contained" color="primary">
                                      {<EditOutlinedIcon />}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </CardContent>
                  </Card>
                </TabPanel>
              </div>
            </Box>
          </Grid>
        </Wrapper>
      </>
    </div>
  );
};

export default OrderDetails;
